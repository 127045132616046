import React from 'react';
import { SectionBase } from '~/components/domains/SectionBase';
import WarningIcon from '~/assets/icon/warning.svg';
import { stepperSteps } from '~/constants/stepperSteps';

export type Props = {};

export const InvalidParamsSection: React.FC<Props> = () => {
  return (
    <SectionBase
      steps={stepperSteps}
      currentStep={-1}
      title="URLが正しくありません"
      centeringTitle
      descriptionIcon={WarningIcon}
      sections={[
        {
          node: '入力されたURLが正しくありません。招待メールに記載されたURLをご利用ください。',
        },
      ]}
    />
  );
};

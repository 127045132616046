import { useRouter } from 'next/router';

const isArray = <T>(target: T | T[]): target is T[] => Array.isArray(target);

export const useResolveQueryValue = <T extends string | number | string[] | number[] | undefined>(
  queryKey: string,
  converter: (query: string | undefined) => T | null
): { loaded: false } | { loaded: true; valid: false } | { loaded: true; valid: true; value: T } => {
  const router = useRouter();
  if (router.isReady === false) return { loaded: false };
  const queryStrVal: string | string[] | undefined = router.query[queryKey];
  if (isArray(queryStrVal))
    throw new Error(
      'resolveQueryValue(...) does not support multiple queries such as `[...path].ts.`'
    );
  const value = converter(queryStrVal);
  if (value === null) return { loaded: true, valid: false };
  return { loaded: true, valid: true, value: value };
};
